import React,{useState,useEffect} from 'react'
import './codnote.css'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress';
import {useLocation, useNavigate} from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {createAnOrder,createAbondend} from '../../features/user/userSlice'
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        content: "'अ'",
        fontSize: 16,
        color: '#fff',
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#d3d3d3',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#d3d3d3' : '#000000',
    width: 32,
    height: 32,
    '&::before': {
      content: "'Aa'",
      fontSize: 16,
      color: '#fff',
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#d3d3d3' : '#d3d3d3',
    borderRadius: 20 / 2,
  },
}));

const CodNote = () => {
const orderInfo=JSON.parse(localStorage.getItem("codOrder"))
const [paySpin,setPaySpin]=useState(false)
const [paySpin1,setPaySpin1]=useState(false)
const loadScript=(src)=>{
  return new Promise((resolve)=>{
      const script=document.createElement("script")
      script.src=src
      script.onload=()=>{
          resolve(true)
      }
      script.onerror=()=>{
          resolve(false)
      }
      document.body.appendChild(script)
  })
}
const generateUniqueId = () => {
  // Get the current date and time
  const now = new Date();

  // Get individual components of the date and time
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const day = now.getDate().toString().padStart(2, '0');
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const seconds = now.getSeconds().toString().padStart(2, '0');
  const milliseconds = now.getMilliseconds().toString().padStart(3, '0');

  // Combine date and time components into a unique string
  const dateTimeString = `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`;

  // Optionally, add some random element to ensure uniqueness
  const randomElement = Math.random().toString(36).substring(2, 10);

  // Combine the dateTimeString with the random element
  const uniqueId = `${dateTimeString}-${randomElement}`;

  return uniqueId;
};

const [uniqueId, setUniqueId] = useState('');

useEffect(() => {
const id = generateUniqueId();
setUniqueId(id);
}, []);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const location = useLocation();
const dispatch=useDispatch()
  useEffect(() => {
      const params = new URLSearchParams(location.search);
      const status = params.get('status');
      if (status === 'success') {
          setPaymentStatus('SUCCESS');
      } else if (status === 'failed') {
          setPaymentStatus('FAILED');
      }
  }, [location.search]);
  const codPay = async() => {
      const res=await loadScript("https://checkout.razorpay.com/v1/checkout.js")
      if(!res){
         alert("Razorpay SDK failed to load")
         return
      }
      const result=await axios.post("https://voguemine2-kd7q.onrender.com/api/user/order/checkout1",{amount:parseInt(200)})
      if(!result){
         alert("Something went wrong")
         return
      }
     
      const {amount,id:order_id,currency}=result.data.order
      const options = {
         key: "rzp_live_CDyasQlE6GSv1I", // Enter the Key ID generated from the Dashboard
         amount: amount,
         currency: currency,
         name: "LevishLuxury",
         description: "LevishLuxury Payment",
         image:"https://levishluxury.com/static/media/logo2.80fe2fbf0e7cd5b31f1b.png",
         order_id: order_id,
         handler: async function (response) {
             const data = {
                 orderCreationId: order_id,
                 razorpayPaymentId: response.razorpay_payment_id,
                 razorpayOrderId: response.razorpay_order_id,
     
             };
     
             const result = await axios.post("https://voguemine2-kd7q.onrender.com/api/user/order/paymentVerification1", data);
             await dispatch(createAnOrder({ totalPrice: orderInfo?.totalPrice, finalAmount: (orderInfo?.finalAmount - 200), shippingCost: 0, orderType: "COD", discount: (orderInfo?.discount), orderItems: orderInfo?.orderItems, paymentInfo: data, shippingInfo: JSON.parse(localStorage.getItem("address")),tag:"LevishLuxury",isPartial:true }))
  addProductToOrderLocalStorage({ totalPrice: orderInfo?.totalPrice, finalAmount: (orderInfo?.finalAmount - 200), shippingCost: 0, orderType: "COD", discount: (orderInfo?.discount), orderItems: orderInfo?.orderItems, paymentInfo: data, shippingInfo: JSON.parse(localStorage.getItem("address")),tag:"LevishLuxury",isPartial:true })
  localStorage.removeItem('cart');
             navigate("/profile")
      setPaySpin(false)
     
         },
         prefill: {
             name: "LevishLuxury",
             email: "levishluxury@gmail.com",
             contact: "9811005235",
         },
         notes: {
             address: "Levish Luxury Premium Quality Clothes",
         },
         theme: {
             color: "#61dafb",
         },
     };
     
     const paymentObject = new window.Razorpay(options);
     paymentObject.open();
       

  };

  const addProductToOrderLocalStorage = (product) => {
    const existingOrder = JSON.parse(localStorage.getItem("orders")) || [];
    const updatedOrder = [...existingOrder, product];
    localStorage.setItem("orders", JSON.stringify(updatedOrder));
  };
  const navigate=useNavigate()

  const modifyCloudinaryUrl = (url) => {
    const urlParts = url?.split('/upload/');
    return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
  };

const phonePe=async()=>{
    const res=await loadScript("https://checkout.razorpay.com/v1/checkout.js")
    if(!res){
       alert("Razorpay SDK failed to load")
       return
    }
    const result=await axios.post("https://voguemine2-kd7q.onrender.com/api/user/order/checkout1",{amount:parseInt(orderInfo?.totalPrice - (orderInfo?.totalPrice/10))})
    if(!result){
       alert("Something went wrong")
       return
    }
   
    const {amount,id:order_id,currency}=result.data.order
    const options = {
       key: "rzp_live_CDyasQlE6GSv1I", // Enter the Key ID generated from the Dashboard
       amount: amount,
       currency: currency,
       name: "LevishLuxury",
       description: "LevishLuxury Payment",
       image:"https://levishluxury.com/static/media/logo2.80fe2fbf0e7cd5b31f1b.png",
       order_id: order_id,
       handler: async function (response) {
           const data = {
               orderCreationId: order_id,
               razorpayPaymentId: response.razorpay_payment_id,
               razorpayOrderId: response.razorpay_order_id,
   
           };
   
           const result = await axios.post("https://voguemine2-kd7q.onrender.com/api/user/order/paymentVerification1", data);
           await dispatch(createAnOrder({ totalPrice: orderInfo?.totalPrice, finalAmount: (orderInfo?.totalPrice - (orderInfo?.totalPrice/10)), shippingCost: 0, orderType: "Prepaid", discount: (orderInfo?.discount), orderItems: orderInfo?.orderItems, paymentInfo: data, shippingInfo: JSON.parse(localStorage.getItem("address")),tag:"LevishLuxury",isPartial:false }))
  addProductToOrderLocalStorage({ totalPrice: orderInfo?.totalPrice, finalAmount: (orderInfo?.totalPrice - (orderInfo?.totalPrice/10)), shippingCost: 0, orderType: "Prepaid", discount: (orderInfo?.discount), orderItems: orderInfo?.orderItems, paymentInfo: data, shippingInfo: JSON.parse(localStorage.getItem("address")),tag:"LevishLuxury",isPartial:false })
  localStorage.removeItem('cart');
           navigate("/profile")
    setPaySpin(false)
   
       },
       prefill: {
           name: "LevishLuxury",
           email: "levishluxury@gmail.com",
           contact: "9811005235",
       },
       notes: {
           address: "Levish Luxury Premium Quality Clothes",
       },
       theme: {
           color: "#61dafb",
       },
   };
   
   const paymentObject = new window.Razorpay(options);
   paymentObject.open();
   

}
const [lang, setLang] = useState("eng");

  const handleLanguageChange = (event) => {
    setLang(event.target.checked ? "hin" : "eng");
  };

const [success,setSuccess]=useState(true)
const cart=JSON.parse(localStorage?.getItem("cart"))
useEffect(()=>{
if(cart?.length>0){
  setSuccess(true)
}
if(cart?.length<1){
  setSuccess(false)
}
},[cart?.length])

  useEffect(() => {
    return () => {
        // Check if the current location is not '/profile'
        if (location.pathname !== '/profile') {
            const addr = JSON.parse(localStorage.getItem("temp"));
            if (addr?.orderItems?.length > 0) {
                // Check if the order hasn't been successfully placed
                    // Create abandoned order
                    if (addr?.shippingInfo?.firstname !== "" && addr?.shippingInfo?.phone !== "" && success===true) {
                        dispatch(createAbondend({
                          shippingInfo:addr?.shippingInfo,
                      tag:addr?.tag,
                      isPartial:true,
                              orderItems:addr?.orderItems,
                              totalPrice:addr?.totalPrice,
                              shippingCost:addr?.shippingCost,
                              orderType:addr?.orderType,
                              discount:addr?.discount,
                              finalAmount:addr?.finalAmount,
                              success:success
                  }));
                }
            }
        }
    };
}, [location]);


useEffect(()=>{
  localStorage.setItem("cod",JSON.stringify({
    cod:true
  }))
})

  return (
    <div className="cod">
      <div className='cod-note left'>
      <div className="banner">
<h1>&#8377; 200 COD CHARGE</h1>
      </div>

        <div className="msg">
          <div className="switchs">
          <p className="note">
          {
            lang==="hin"?"*आवश्यक सूचना":"*Important Note"
          }
        </p>
          <div className="lang1">
      <FormControlLabel
      control={
        <MaterialUISwitch 
          sx={{ m: 1 }} 
          checked={lang === "hin"} 
          onChange={handleLanguageChange} 
          className='switch'
        />
      }
    />
      </div>
          </div>
       {
        lang==="hin"?
                
                <div className="msg">
<p>प्रिय उपभोगता,</p>
<p>आप हमारे लिए एक बहुमूल्य ग्राहक हे इसी क्रम को आगे बढ़ाते हुए अपनी सेवा को बेहतर बनाने और अपने वास्तविक ग्राहकों की सुरक्षा के लिए, अब हम आपसे COD ऑर्डर के लिए 200 रुपये का एक छोटा सा आंशिक भुगतान चाहते है। दुर्भाग्य से, कुछ व्यक्तियों द्वारा नकली ऑर्डर देने और नकली व्यवसायों की मौजूदगी के कारण, यह कदम सुनिश्चित करने के लिए अत्यन्त आवश्यक है कि हमारे साथ आपका अनुभव सहज और विश्वसनीय बना रहे।</p>
<p>हम आपके सहयोग और निरंतर समर्थन के लिए दिल की गहराई से आप की सराहना करते हैं। हमारे साथ खरीदारी करने हेतु आप का बहुत बहुत  धन्यवाद!
</p>
<p>सादर</p>
        
      </div>
      :
      <div className="mgs">
        <p>Dear User,</p>
        <p>You are a valuable customer to us. In order to further improve our services and protect our genuine customers, we now require a small partial payment of Rs 200 for COD orders. Unfortunately, due to the prevalence of individuals placing fake orders and fake businesses, this step is necessary to ensure that your experience with us remains smooth and reliable.
        </p>
        
        <p>We deeply appreciate your cooperation and continued support. Thank you very much for shopping with us!
        </p>
        <p>Regards</p>
                </div>

       }
        
      </div>




     
      
      <div className="pay-btn">
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
                        {
                            paySpin===true?<CircularProgress />:
                            <button className='pay' disabled={paySpin} onClick={codPay}><p>Complete Partial Payment (Rs.200)</p> <p>for COD</p></button>

                        }
                    
                    </div>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
                        {
                            paySpin1===true?<CircularProgress />:
                            <button className='pay1' disabled={paySpin1} onClick={phonePe}><p>Pay Via UPI/ Card/ Net Banking</p> <p>For 10% Off <span style={{textDecoration:'line-through'}}>{orderInfo?.finalAmount}</span> <span>{orderInfo?.totalPrice - (orderInfo?.totalPrice/10)}</span></p></button>

                        }
                    
                    </div>
      </div>
    </div>
    <div className="right">

                {
                    orderInfo?.orderItems?.map((item,index)=>{
                        return(
                            <div className="prdt" key={index}>
                    <div className="detail">
                        <img src={modifyCloudinaryUrl(item?.product?.images[0]?.url)} alt=""/>
                        <div><p className="p-name">{item?.product?.title}</p>
                        <p className="size"><span>{item?.size}</span><span>/</span><span>{item?.color}</span></p></div>
                    </div>
                    <p className="p-price">&#8377;{(item?.price)*(item?.quantity)}</p>
                </div>
                        )
                    })
                }
                <div className="total">
                    <ul>
                        <li>Subtotal</li>
                        <li>Shipping</li>
                        <li>Discount</li>
                        <li style={{fontSize:'20px',fontWeight:600}}>Total</li>
                    </ul>
                    <ul>
                        <li>&#8377; {orderInfo?.totalPrice}</li>
                        <li>&#8377; {orderInfo?.shippingCost}</li>
                        <li>&#8377; -{orderInfo?.discount}</li>
                        <li style={{fontSize:'20px',fontWeight:600}}>&#8377; {orderInfo?.finalAmount}</li>
                    </ul>
                </div>
            </div>
    </div>
    
  )
}

export default CodNote
