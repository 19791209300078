import React, { useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import home_benner from '../../images/home-banner.jpg'
import 'react-multi-carousel/lib/styles.css';
import { Link, Navigate,useNavigate } from 'react-router-dom'
import './homemain.css'
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllProducts } from '../../features/products/productSlice';
import Product from '../../components/Product'
import Reviews from './Reviews';

const HomeMain = () => {
  const navigate=useNavigate()
  const bannerState=JSON.parse(localStorage.getItem("banners"))
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const collections = ["Men's Premium Shirts", "Flat Sandals", "Men's Belt"];
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const page = 1;
  const limit = 10;
  const productState = useSelector((state) => state?.product?.product);
  const productStat = useSelector((state) => state?.product);

  const { isError, isLoading, isSuccess } = productStat
  const sort = "-createdAt"
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const promises = collections.map((collectionName) => dispatch(getAllProducts({ limit, collectionName, page, sort })));

        const results = await Promise.all(promises);
        
        // Logging results to check the structure
        console.log('Results:', results);

        const combinedPayArray = results.reduce((accumulator, currentObject) => {
          // Check if payload exists and is an array
          if (Array.isArray(currentObject.payload.products)) {
            return [...accumulator, ...currentObject.payload.products];
          } else {
            console.warn('Payload is not an array:', currentObject.payload);
            return accumulator;
          }
        }, []);

        setData(combinedPayArray);
      } catch (error) {
        console.error('Failed to fetch products:', error);
      }
    };

    fetchProducts();
  }, [limit, page]);
  const products = productState ? productState : [];

  const shirts = data.filter(object => object.collectionName && object.collectionName === "Men's Premium Shirts").slice(0, 4);
  const tshirt = data.filter(object => object.collectionName && object.collectionName === "Flat Sandals").slice(0, 4);
  const jeans = data.filter(object => object.collectionName && object.collectionName === "Men's Belt").slice(0, 4);


  useEffect(() => {
    if (isLoading && data) {
      setLoading(true)
    }
    if (isSuccess && data) {
      setLoading(false)
    }
  }, [isLoading, isSuccess])

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const modifyCloudinaryUrl = (url) => {
    const urlParts = url?.split('/upload/');
    return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
  };
  return (
    <div className='homeMain'>
      <div className="hero-section">
      <div className="shoes-section">
          <img src={modifyCloudinaryUrl(bannerState[0]?.images[0]?.url)} alt="" className='simg1'/>
          <img src={modifyCloudinaryUrl(bannerState[23]?.images[0]?.url)} alt="" className='simg2'/>
          <img src={modifyCloudinaryUrl(bannerState[24]?.images[0]?.url)} alt="" className='simg3'/>
          <img src={modifyCloudinaryUrl(bannerState[25]?.images[0]?.url)} alt="" className='simg4'/>
          <img src={modifyCloudinaryUrl(bannerState[26]?.images[0]?.url)} alt="" className='simg5'/>

        </div>
      </div>
      <div className="categories">
          <Link to="/men">
            <div className="cate">
              <img src={modifyCloudinaryUrl(bannerState[1]?.images[0]?.url) || "https://res.cloudinary.com/keshvendra/image/upload/v1713558263/01_hwvjvv.jpg"} alt={bannerState[1]?.alt} />
            </div>
          </Link><Link to="/women">
            <div className="cate">
              <img src={modifyCloudinaryUrl(bannerState[2]?.images[0]?.url) || "https://res.cloudinary.com/keshvendra/image/upload/v1713558263/02_v6acp2.jpg"} alt={bannerState[2]?.alt} />
            </div>
          </Link><Link to="/kids">
            <div className="cate">
              <img src={modifyCloudinaryUrl(bannerState[3]?.images[0]?.url) || "https://res.cloudinary.com/keshvendra/image/upload/v1713558265/03_dncxwq.jpg"} alt={bannerState[3]?.alt} />
            </div>
          </Link><Link to="/accessories">
            <div className="cate">
              <img src={modifyCloudinaryUrl(bannerState[4]?.images[0]?.url) || "https://res.cloudinary.com/keshvendra/image/upload/v1713558266/05_jatmmj.jpg"} alt={bannerState[4]?.alt} />
            </div>
          </Link>




      </div>

      <div style={{margin:'0 10px'}}>
        <div className="shoes-section">
          <img src={modifyCloudinaryUrl(bannerState[5]?.images[0]?.url)} alt="" />
        </div>
        <div className="products-listing">
          <p className="section-heading">Men's Featured Products</p>


          {
            loading ? <div className="skeleton">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
              :
              <div className="product-list pl1">


{
                  shirts.map((arm, index) => {

                    return <Product key={index} keys={index} id={arm?._id} img={arm?.images} title={arm?.title} price={arm?.price} variants={arm?.variants} handle={arm?.handle} prdt={arm}/>


                  })
                }

               

              </div>
          }




        </div>
       
        <div className="shoes-section">

        <div className="shoes-section">
          <img src={modifyCloudinaryUrl(bannerState[6]?.images[0]?.url)} alt="" />
        </div>
        </div>
        <div className="products-listing">
          <p className="section-heading">Featured Products</p>

          {
            loading ? <div className="skeleton">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
              :
              <div className="product-list">
                {

                  tshirt.map((arm, index) => {

                    return <Product key={index} id={arm?._id} img={arm?.images} title={arm?.title} price={arm?.price} variants={arm?.variants} handle={arm?.handle} prdt={arm}/>


                  })
                }

              </div>
          }


        </div>
       
        <div className="shoes-section">
        <div className="shoes-section">
          <img src={modifyCloudinaryUrl(bannerState[7]?.images[0]?.url)} alt="" />
        </div>
        </div>
        <div className="products-listing">
          <p className="section-heading">Featured Products</p>
          {
            loading ? <div className="skeleton">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
              :
              <div className="product-list">
                {

                  jeans.map((arm, index) => {

                    return <Product key={index} id={arm?._id} img={arm?.images} title={arm?.title} price={arm?.price} variants={arm?.variants} handle={arm?.handle} prdt={arm}/>


                  })
                }

              </div>
          }



        </div>

        {/* <div className="ratings">
          <Reviews/>
        </div> */}

<p style={{textAlign:'center',fontSize:'30px',fontWeight:500}}>Secure With Levish Luxury</p>

        <div className="icon-footer">
          <div>
          <img src={modifyCloudinaryUrl(bannerState[8]?.images[0]?.url) || modifyCloudinaryUrl("https://res.cloudinary.com/dqh6bd766/image/upload/v1712582661/FREE_FAST_SHIPPING_IN_INDIA_qug5zj.png")} alt="" />
<p>Fast Shipping</p>
          </div>
          <div>
          <img src={modifyCloudinaryUrl(bannerState[9]?.images[0]?.url) || modifyCloudinaryUrl("https://res.cloudinary.com/dqh6bd766/image/upload/v1712582661/SECURE_PAYMENT_eblv7s.png")} alt="" />
<p>Secure Payment</p>
          </div>
          <div>
          <img src={modifyCloudinaryUrl(bannerState[10]?.images[0]?.url) || modifyCloudinaryUrl("https://res.cloudinary.com/dqh6bd766/image/upload/v1712582665/Easy_Exchange_gfloux.png")} alt="" />

<p>Easy Exchange</p>
          </div>
        </div>




      </div>

    </div>
  )
}

export default HomeMain
